var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-search-box",
        { ref: "searchbox", on: { enter: _vm.getList } },
        [
          _c("template", { slot: "search" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-plant", {
                  attrs: {
                    type: "none",
                    name: "plantCd",
                    disabled: _vm.plantDisabled,
                  },
                  model: {
                    value: _vm.searchParam.plantCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "plantCd", $$v)
                    },
                    expression: "searchParam.plantCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-process", {
                  attrs: { label: "LBLPROCESS", name: "processCd" },
                  model: {
                    value: _vm.searchParam.processCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "processCd", $$v)
                    },
                    expression: "searchParam.processCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-select", {
                  attrs: {
                    stepperGrpCd: "IBM_STEP_CD",
                    type: "search",
                    itemText: "stepperMstNm",
                    itemValue: "stepperMstCd",
                    name: "ibmStepCd",
                    label: "개선진행단계",
                  },
                  model: {
                    value: _vm.searchParam.ibmStepCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "ibmStepCd", $$v)
                    },
                    expression: "searchParam.ibmStepCd",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "c-table",
        {
          ref: "table",
          attrs: {
            title: "위험등록부 목록",
            tableId: "riskRegister01",
            columns: _vm.gridRisk.columns,
            data: _vm.gridRisk.data,
            gridHeight: _vm.gridRisk.height,
            merge: _vm.gridRisk.merge,
            editable: _vm.editable,
            selection: _vm.popupParam.type,
            rowKey: "ramSignificantRiskRegisterId",
          },
          on: { linkClick: _vm.linkClick },
          scopedSlots: _vm._u([
            {
              key: "customArea",
              fn: function ({ props }) {
                return [
                  props.row["sopImprovementIds"] && props.row["ibmStepNames"]
                    ? _vm._l(
                        _vm.chipDatas(
                          props.row["sopImprovementIds"],
                          props.row["ibmStepNames"]
                        ),
                        function (item, index) {
                          return _c(
                            "q-chip",
                            {
                              key: index,
                              staticStyle: {
                                "margin-bottom": "4px !important",
                              },
                              attrs: {
                                color: item.color,
                                clickable: true,
                                "text-color": "white",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.openImpr(item, props.row)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(item.title) + " ")]
                          )
                        }
                      )
                    : void 0,
                ]
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _c("c-btn", {
                    attrs: { label: "LBLSEARCH", icon: "search" },
                    on: { btnClicked: _vm.getList },
                  }),
                  _c("c-btn", {
                    attrs: { label: "LBLSELECT", icon: "check" },
                    on: { btnClicked: _vm.select },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }